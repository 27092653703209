import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private _authService: AuthService, private _router: Router) {

    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.routeActivatable();
    }
    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.routeActivatable();
    }
    private routeActivatable = (): boolean => {
        if (this._authService.isAuthenticated) {
            return true;
        }
        this._router.navigateByUrl("/home");
        return false;
    }
}