import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { InMemoryTranslateHttpLoader } from './services/in-memory-translate-loader';
import { filter, mergeMap, share, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { AuthService } from './services/auth.service';
import { CultureService } from './services/culture.service';
import { DialogService } from './services/dialog.service';
import { HttpService } from './services/http.service';
import { SpinnerService } from './services/spinner.service';
import { ProfileService } from './services/profile.service';
import { PerformancesService } from 'app/services/performances.service';
import { UsersService } from 'app/services/users.service';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { SnackBarService } from './services/snackbar.service';
import { ReportsService } from 'app/services/reports.service';
import { WalletsService } from 'app/services/wallets.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRestInterceptor } from './services/http-rest.interceptor';
import { InstancesService } from 'app/services/instances.service';
import { CountriesService } from 'app/services/countries.service';
import { TourService } from 'app/services/tour.service';
import { FaqService } from 'app/services/faq.service';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import {TermsConditionsService} from "../services/terms-conditions.service";
import {StripeService} from "../services/stripe.service";
registerLocaleData(localeIt);
registerLocaleData(localeEn);




@NgModule()
export class CoreModule {
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,

            providers: [
                AuthService,
                CultureService,
                DialogService,
                HttpService,
                SpinnerService,
                SnackBarService,
                ProfileService,
                UsersService,
                SubscriptionsService,
                TourService,
                ReportsService,
                StripeService,
                WalletsService,
                InstancesService,
                FaqService,
                CountriesService,
                TermsConditionsService,
                PerformancesService,
                {
                    provide: DateAdapter,
                    useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]
                },
                {
                    provide: MAT_DATE_FORMATS,
                    useValue: MAT_MOMENT_DATE_FORMATS
                },
                {
                    provide: MAT_DATE_LOCALE,
                    useValue: "it-IT"
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpRestInterceptor,
                    multi: true
                },

                {
                    provide: APP_INITIALIZER,
                    useFactory: languageLoader,
                    deps: [TranslateService, CultureService],
                    multi: true
                },

                {
                    provide: APP_INITIALIZER,
                    useFactory: loadUser,
                    deps: [AuthService, ProfileService],
                    multi: true
                }
            ]
        };
    }
    constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry, @Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }

        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
    }
}

export function languageLoader(translateService: TranslateService, cultureService: CultureService) {
    return function () {
        return (<InMemoryTranslateHttpLoader>translateService.currentLoader).initialize().pipe(tap(() => {
            cultureService.initialize();
        })).toPromise();
    }
}

export function loadUser(authService: AuthService, profileService: ProfileService) {
    return () => {
        const base = authService.verifyLogin().pipe(share());

        const loggedIn = base.pipe(filter(result => result === true)).pipe(mergeMap(result => {
            return profileService.get();
        }));
        const noLoggedIn = base.pipe(filter(result => {
            return result === false
        }));
        return merge(loggedIn, noLoggedIn).toPromise();
    };
}
