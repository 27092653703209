import { Injectable } from '@angular/core';
import { SearchResult } from 'app/core/components/data-table/types';
import { HttpService } from 'app/core/services/http.service';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import buildQuery from 'odata-query'
import { Buy, Transfer, Wallet, WalletAutocomplete, WalletListItem, WalletsSearchCriteria, WalletToSave, WalletTransaction, WalletTransactionSearchCriteria } from 'app/models/wallets.model';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class WalletsService {
    constructor(private _httpService: HttpService) {
    }

    public searchWallets = (searchCriteria: WalletsSearchCriteria): Observable<SearchResult<WalletListItem>> => {
        const filters = searchCriteria.toOdata();
        filters.filter = { and: [] };
        if (searchCriteria.freeText) {
            filters.filter.and.push({ Name: { contains: searchCriteria.freeText } });
        }

        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/wallets${queryParams}`);
    }

    public getAllWallets = (): Observable<WalletListItem[]> => {
        const searchCriteria = new WalletsSearchCriteria();
        //searchCriteria.itemsPerPage = 100000;
        searchCriteria.itemsPerPage = 100;
        searchCriteria.pageIndex = 1;
        return this.searchWallets(searchCriteria).pipe(map(m => m.items));
    }

    public autocomplete = (searchString: string): Observable<WalletAutocomplete[]> => {
        let searchCriteria = new WalletsSearchCriteria();
        searchCriteria.pageIndex = 1;
        searchCriteria.itemsPerPage = 100;
        searchCriteria.freeText = searchString;
        const filters = searchCriteria.toOdata();
        filters.filter = { and: [{ Name: { contains: searchCriteria.freeText } }] };
        filters.select = "id,name";

        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/wallets${queryParams}`).pipe(map(m => {
            return m.items;
        }));
    }

    public getWallet = (id: string): Observable<Wallet> => {
        return this._httpService.get(`${environment.apiUrl}/wallets/${encodeURIComponent(id)}`);
    }

    public addWallet = (wallet: WalletToSave): Observable<string> => {
        return this._httpService.post(`${environment.apiUrl}/wallets`, wallet).pipe(map((result: string) => {
            let parts = result.split('/');
            return parts[parts.length - 1];
        }));
    }

    public editWallet = (id: string, wallet: WalletToSave): Observable<void> => {
        return this._httpService.put(`${environment.apiUrl}/wallets/${encodeURIComponent(id)}`, wallet);
    }

    public deleteWallet = (id: string): Observable<void> => {
        return this._httpService.delete(`${environment.apiUrl}/wallets/${encodeURIComponent(id)}`);
    }

    public searchWalletTransactions = (walletId: string, searchCriteria: WalletTransactionSearchCriteria): Observable<SearchResult<WalletTransaction>> => {
        const filters = searchCriteria.toOdata();
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/wallets/${walletId}/transactions${queryParams}`);
    }

    public transfer = (id: string, transfer: Transfer): Observable<void> => {
        return this._httpService.post(`${environment.apiUrl}/wallets/${encodeURIComponent(id)}/transfer`, transfer);
    }

    public buy = (id: string, buy: Buy): Observable<any> => {
        return this._httpService.post(`${environment.apiUrl}/wallets/${encodeURIComponent(id)}/buy`, buy).pipe(tap(result => {
            window.location.href = result;
        }));
    }
}
