import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseAnimations } from '@fuse/animations';
import { BaseDialogComponent } from './base-dialog.component';




@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    animations: FuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmDialogComponent extends BaseDialogComponent implements OnInit {

    public confirmLabel: string;
    public cancelLabel: string;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super()
    }

    ngOnInit(): void {
        this.cancelLabel = this.cancelLabel != null ? this.cancelLabel : 'common.undo';
        this.confirmLabel = this.confirmLabel != null ? this.confirmLabel : 'common.confirm';

        console.log(this.confirmLabel+" confirm label");
    }
    public close = (): void => {
        this.dialogRef.close();
    }
}
