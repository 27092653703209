import { Injectable } from "@angular/core";
import { HttpService } from "app/core/services/http.service";
import { Observable, of } from "rxjs";
import { Faq, FaqCulture } from "app/models/faq.model";
import { map } from "rxjs/operators";

@Injectable()
export class FaqService {
    private _faqs: FaqCulture[] = null;
    constructor(private _httpService: HttpService) {
    }

    public get = (culture: string): Observable<Faq[]> => {
        let obs = of(this._faqs)
        if (!this._faqs) {
            obs = this._httpService.get("/assets/i18n/faq.json");
        }
        return obs.pipe(map(m => {
            return m.find(f => f.culture == culture).faqs;
        }))
    }
}
