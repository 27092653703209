
/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: "Common",
        type: 'group',
        children: [
            {
                id: 'startTour',
                title: 'Start Tour',
                type: 'basic',
                icon: 'heroicons_solid:film',
                link: '/dashboard-start-tour',
                classes: {
                    wrapper: "bottom1"
                }
            },
            {
                id: 'faq',
                title: 'Faq',
                type: 'basic',
                icon: 'heroicons_solid:bookmark',
                link: 'https://cineecg.com/#faq',
                externalLink: true,
                classes: {
                    wrapper: "bottom2"
                }
            },
            {
                id: 'info',
                title: 'Info',
                type: 'basic',
                icon: 'heroicons_solid:information-circle',
                link: 'https://cineecg.com/insights/',
                externalLink: true,
                classes: {
                    wrapper: "bottom3"
                }
            },
        ]
    },
    {
        id: "SystemUser",
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:home',
                link: '/dashboard',
                tourTitle: 'tour.systemUser.dashboard.title',
                tourMessage: 'tour.systemUser.dashboard.message'
            },
            {
                id: 'services',
                title: 'performances.performances',
                type: 'basic',
                icon: 'heroicons_solid:heart',
                link: '/admin/performances',
                tourTitle: 'tour.systemUser.services.title',
                tourMessage: 'tour.systemUser.services.message'
            },

            {
                id: 'reports',
                title: 'reports.reports',
                type: 'basic',
                icon: 'heroicons_solid:currency-euro',
                link: '/admin/reports',
                tourTitle: 'tour.systemUser.reports.title',
                tourMessage: 'tour.systemUser.reports.message'
            },

            {
                id: 'organizationsUsers',
                title: 'organizationsUsers.organizationsUsers',
                type: 'basic',
                icon: 'heroicons_solid:users',
                link: '/admin/organizations-users',
                tourTitle: 'tour.systemUser.organizationsUsers.title',
                tourMessage: 'tour.systemUser.organizationsUsers.message'
            }
        ]
    },
    {
        id: "OrganizationAdmin",
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:home',
                link: '/dashboard',
                tourTitle: 'tour.organizationAdmin.dashboard.title',
                tourMessage: 'tour.organizationAdmin.dashboard.message'
            },
            {
                id: 'services',
                title: 'performances.performances',
                type: 'basic',
                icon: 'heroicons_solid:heart',
                link: '/organization/performances',
                mainTitle: 'performances.performances',
                tourTitle: 'tour.organizationAdmin.services.title',
                tourMessage: 'tour.organizationAdmin.services.message'
            },
            {
                id: 'subscriptions',
                title: 'subscriptions.subscriptions',
                type: 'basic',
                icon: 'heroicons_solid:cube-transparent',
                link: '/organization/subscriptions',
                mainTitle: 'common.administration',
                tourTitle: 'tour.organizationAdmin.subscriptions.title',
                tourMessage: 'tour.organizationAdmin.subscriptions.message'
            },
            {
                id: 'wallets',
                title: 'wallets.wallets',
                type: 'basic',
                icon: 'heroicons_solid:shopping-bag',
                link: '/organization/wallets',
                mainTitle: 'common.administration',
                tourTitle: 'tour.organizationAdmin.wallets.title',
                tourMessage: 'tour.organizationAdmin.wallets.message'
            },

            {
                id: 'users',
                title: 'organizationsUsers.users',
                type: 'basic',
                icon: 'heroicons_solid:users',
                link: '/organization/users',
                mainTitle: 'common.administration',
                tourTitle: 'tour.organizationAdmin.users.title',
                tourMessage: 'tour.organizationAdmin.users.message'
            },

            {
                id: 'reports',
                title: 'reports.reports',
                type: 'basic',
                icon: 'heroicons_solid:currency-euro',
                link: '/organization/reports',
                mainTitle: 'common.administration',
                tourTitle: 'tour.organizationAdmin.reports.title',
                tourMessage: 'tour.organizationAdmin.reports.message'
            }
        ]
    },
    {
        id: "SingleUser",
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:home',
                link: '/dashboard',
                tourTitle: 'tour.singleUser.dashboard.title',
                tourMessage: 'tour.singleUser.dashboard.message'
            },
            {
                id: 'services',
                title: 'performances.performances',
                type: 'basic',
                icon: 'heroicons_solid:heart',
                link: '/user/performances',
                tourTitle: 'tour.singleUser.services.title',
                tourMessage: 'tour.singleUser.services.message'
            },
            {
                id: 'instances',
                title: 'instances.instances',
                type: 'basic',
                icon: 'heroicons_solid:qrcode',
                link: '/user/instances',
                tourTitle: 'tour.singleUser.instances.title',
                tourMessage: 'tour.singleUser.instances.message'
            }
        ]
    },
    {
        id: "OrganizationUser",
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:home',
                link: '/dashboard',
                tourTitle: 'tour.organizationUser.dashboard.title',
                tourMessage: 'tour.organizationUser.dashboard.message'
            },
            {
                id: 'services',
                title: 'performances.performances',
                type: 'basic',
                icon: 'heroicons_solid:heart',
                link: '/user/performances',
                tourTitle: 'tour.organizationUser.services.title',
                tourMessage: 'tour.organizationUser.services.message'
            },
            {
                id: 'instances',
                title: 'instances.instances',
                type: 'basic',
                icon: 'heroicons_solid:qrcode',
                link: '/user/instances',
                tourTitle: 'tour.organizationUser.instances.title',
                tourMessage: 'tour.organizationUser.instances.message'
            }
        ]
    }
];
