import { SearchCriteria } from "app/core/components/data-table/types";

export class UserListItem {
    public id: string;
    public registrationDate: string;
    public organizationName: string;
    public type: UserTypes;
    public fullName: string;
    public email: string;
    public enabled: boolean = true;
}

export class OrganizationOrUser {
    public email: string;
    public enabled: boolean = true;
    public allPerformances: boolean = true;
    public id: string;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public registrationDate: string;
    public type: UserTypes;
    public performances: UserPerformance[] = [];
}
export class OrganizationOrUserToSave {
    public email: string;
    public enabled: boolean = true;
    public allPerformances: boolean = true;
    public performances: UserPerformanceToSave[] = [];
    public firstName: string;
    public lastName: string;
}


export class UserPerformanceToSave {
    public performanceId: string;
    public enabled: boolean;
    public type?: PerformancePriceTypes;
    public value?: number;
}
export class UserPerformance {
    public name: string;
    public performanceId: string;
    public enabled: boolean;
    public type?: PerformancePriceTypes;
    public value?: number;
}

export enum PerformancePriceTypes {
    Default = "Default",
    FixedPrice = "FixedPrice",
    Discount = "Discount"
}

export enum UserTypes {
    OrganizationUser = "OrganizationUser",
    OrganizationAdmin = "OrganizationAdmin",
    SingleUser = "SingleUser"
}

export class UsersSearchCriteria extends SearchCriteria {
    public type: UserTypes = null;
    public freeText: string = null;
}

export class SingleUser {
    public email: string;
    public firstName: string;
    public address: string;
    public lastName: string;
    public fiscalCode: string;
    public vatCode: string;
}
export class UserAutocomplete {
    public id: string;
    public fullName: string;
}
