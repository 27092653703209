import { SearchCriteria } from "app/core/components/data-table/types";
import { SelectListitem } from "app/core/components/forms/types";
import { BuyModes } from "./performance.model";

export class InstanceListItem {
    public id: string;
    public performanceId: string
    public performanceName: string
    public amount: number;
    public date: string;
    public status: InstanceStatuses;
    public downloads: string;
}

export class InstancesSearchCriteria extends SearchCriteria {
    public freeText: string = null;
}

export class Instance {
    public id: string;
    public name: string;
    public description: string;
    public image: string;
    public price: number;
    public buyMode: BuyModes;
    public fields: InstanceField[];
}

export class InstanceView {
    public id: string;
    public amount: number;
    public date: string;
    public downloads: string[];
    public fields: InstanceFieldView[];
    public performanceId: string;
    public performanceName: string;
    public status: InstanceStatuses;
}

export class InstanceFieldView {
    public label: string;
    public type: InstanceFieldTypes;
    public value: string;
}

export class InstanceField {
    public key: string;
    public required: boolean;
    public type: InstanceFieldTypes;
    public label: string;
    public help: string;
    public extensions: [];
    public items: SelectListitem[];
}

export enum InstanceStatuses {
    ToPay = "ToPay",
    PaymentFailed = "PaymentFailed",
    Pending = "Pending",
    Completed = "Completed",
    Aborted = "Aborted",
    Refund = "Refund"
}

export enum InstanceFieldTypes {
    Text = "Text",
    Flag = "Flag",
    List = "List",
    File = "File"
}

export class InstanceToSave {
    public redirectUri: string
    public koRedirectUri: string
    public useWallet: boolean;
    constructor(public fields: any = {}, forUser: boolean) {
        const baseRedirectUri = `${document.location.origin}/${forUser ? 'user' : 'organization'}/instances/from-buy?result=`;
        this.redirectUri = `${baseRedirectUri}true`;
        this.koRedirectUri = `${baseRedirectUri}false`;
        for (let prop in fields) {
            if (fields[prop] === true || fields[prop] === false) {
                fields[prop] = fields[prop].toString();
            }

        }
    }
}
