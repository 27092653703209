import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { tap } from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BaseProfile, Profile, RoleTypes } from '../models/profile.model';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private _user: ReplaySubject<Profile> = new ReplaySubject<Profile>(1);
    constructor(private _httpService: HttpService) {
    }

    get user$(): Observable<Profile> {
        return this._user.asObservable();
    }

    public update = (user: BaseProfile): Observable<Profile> => {
        return this._httpService.put(`${environment.apiUrl}/users/profile`, user).pipe(mergeMap((response) => {
            return this.get();
        }));
    }

    public get = (): Observable<Profile> => {
        return this._httpService.get(`${environment.apiUrl}/users/profile`).pipe(map(result => {
            let ret = <Profile>{ ...new Profile(), ...result }
            this._user.next(ret);
            return ret;
        }));
    }

    public getAskBuyMode = (): Observable<boolean> => {
        return this.get().pipe(map(m => {
            const value = localStorage.getItem(m.email + "_ask");
            return value != null;
        }));
    }

    public setAskBuyMode = (ask): void => {
        this.get().pipe(map(m => {
            if (ask) {
                localStorage.removeItem(m.email + "_ask");
            }
            else {
                localStorage.setItem(m.email + "_ask", "true");
            }
        })).subscribe();
    }

}
