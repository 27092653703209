import {Component, Injectable, Type} from "@angular/core";
import {PrivacyV1Component} from "../core/components/terms-conditions/privacy-v1/privacy-v1.component";
import {TermsV1Component} from "../core/components/terms-conditions/terms-v1/terms-v1.component";
import {TermsConditionsModel, TermsConditionsRequest} from "../models/terms-conditions.model";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {HttpService} from "../core/services/http.service";
import {KeyValue} from "@angular/common";
import {brewer} from "chroma-js";

@Injectable()
export class TermsConditionsService {

    private termsAndConditions: TermsConditionsModel[] = [];

    constructor(private _httpService: HttpService) {
        this.termsAndConditions.push(new TermsConditionsModel('privacy-v1', 'termsConditions.privacy', PrivacyV1Component, true, false));
        this.termsAndConditions.push(new TermsConditionsModel('terms-v1', 'termsConditions.termsAndConditions', TermsV1Component, true, false));
    }

    public getEnumStatus = () => {
        // console.log(this.termsAndConditions.get('privacy-v1'));
        return this.termsAndConditions;
    }

    public updateTermsConditions = (key: string, accepted: boolean): Observable<TermsConditionsRequest> => {
        const terms = new TermsConditionsRequest(key, accepted);
        return this._httpService.post(`${environment.apiUrl}/users/termsConditions`, terms);
    }

    public allRequiredAccepted(termsConditions: KeyValue<string, boolean>[]): boolean{
        let allRequiredAccepted = true;
        if (termsConditions != null){
            Object.keys(termsConditions).forEach((key) => {
                if (termsConditions[key]){
                    // console.log('termsConditions not accepted : ', key);
                    allRequiredAccepted = false;
                    return;
                }
            });
        }
        return allRequiredAccepted;
    }
}
