import { Injectable } from '@angular/core';
import { SearchResult } from 'app/core/components/data-table/types';
import { HttpService } from 'app/core/services/http.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import buildQuery from 'odata-query'
import { Report, ReportListItem, ReportsSearchCriteria } from 'app/models/reports.model';
import moment from 'moment';
import { map } from 'rxjs/operators';
import {InstanceStatuses} from "../models/instance.model";

@Injectable()
export class ReportsService {
    constructor(private _httpService: HttpService) {
    }

    public searchReports = (searchCriteria: ReportsSearchCriteria): Observable<SearchResult<ReportListItem>> => {
        const filters = searchCriteria.toOdata();
        filters.filter = { and: [] };
        if (searchCriteria.id) {
            filters.filter.and.push({ Id: searchCriteria.id });
        }
        if (searchCriteria.from) {
            const fromDate = moment.utc(searchCriteria.from).toDate();
            filters.filter.and.push({ Date: { ge: fromDate } });
        }
        if (searchCriteria.to) {
            const toDate = moment.utc(searchCriteria.to).add(1, 'day').toDate();
            filters.filter.and.push({ Date: { lt: toDate } });
        }
        if (searchCriteria.freeText) {

            filters.filter.and.push({
                or:
                    [
                        { PerformanceName: { contains: searchCriteria.freeText } },
                        { UserFullName: { contains: searchCriteria.freeText } },
                        { FirstLastName: { contains: searchCriteria.freeText } },
                        { OrganizationName: { contains: searchCriteria.freeText } },
                        { Id: { contains: searchCriteria.freeText } },
                        { Status: this.getEnumStatus(searchCriteria.freeText)}
                    ]
            });
        }
        if (searchCriteria.userCountryType) {
            filters.filter.and.push({ UserCountryType: searchCriteria.userCountryType });
        }
        if (searchCriteria.userType) {
            filters.filter.and.push({ UserType: searchCriteria.userType });
        }
        if (searchCriteria.sanitary != null) {
            filters.filter.and.push({ SanitaryPerformance: searchCriteria.sanitary });
        }
        const queryParams = buildQuery(filters);
        // console.log(queryParams);
        return this._httpService.get(`${environment.apiUrl}/reports${queryParams}`);
    }

    public getEnumStatus = (value: string) => {
        switch (value.toLocaleLowerCase().trim()){
            case 'topay' :
                return InstanceStatuses.ToPay;
                break;
            case 'pay' :
                return InstanceStatuses.ToPay;
                break;
            case 'aborted' :
                return InstanceStatuses.Aborted;
                break;
            case 'refund' :
                return InstanceStatuses.Refund;
                break;
            case 'completed' :
                return InstanceStatuses.Completed;
                break;
            case 'pending' :
                return InstanceStatuses.Pending;
                break;
            case 'paymentfailed' :
                return InstanceStatuses.PaymentFailed;
                break;
            case 'failed' :
                return InstanceStatuses.PaymentFailed;
                break;
        }
    }

    public getReport = (id: string): Observable<Report> => {
        const searchCriteria = new ReportsSearchCriteria();
        searchCriteria.id = id;
        return this.searchReports(searchCriteria).pipe(map(result => {
            return result.items.any() ? <Report>result.items[0] : null;
        }));
    }


    public refunds = (id: string): Observable<void> => {
        return this._httpService.post(`${environment.apiUrl}/reports/${encodeURIComponent(id)}/refund`, {});
    }
}
