import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {TermsConditionsService} from "../../services/terms-conditions.service";
import {ProfileService} from "../services/profile.service";
import {MessageService} from "primeng-lts/api";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class TermsConditionsGuard implements CanActivate, CanActivateChild {
    constructor(private _router: Router, private _profileService: ProfileService, private _termsConditionsService: TermsConditionsService,
                private messageService: MessageService, private _translateService: TranslateService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkTermsConditions();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkTermsConditions();
    }

    private checkTermsConditions(): boolean{
       // console.log('TermsConditionsGuard');
        /*TODO just now remove this check */
       /* this._profileService.user$.subscribe(result => {
            //console.log('result.allRequiredAccepted: ', this._termsConditionsService.allRequiredAccepted(result.termsConditions));
            if (!this._termsConditionsService.allRequiredAccepted(result.termsConditions)){
                //console.log('allRequiredAccepted false');
                this._router.navigateByUrl("/profile");
                this.messageService.add({key: 'globalToast', severity: 'warn',
                    summary: this._translateService.instant('termsConditions.warningTitle'),
                    detail: this._translateService.instant('termsConditions.warningMessageMustAccept')});
                return false;
            } else {
                console.log("all terms conditions required accepted");
            }
        });*/
        return true;
    }
}
