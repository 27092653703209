import {Injectable} from "@angular/core";
import {HttpService} from "../core/services/http.service";
import {environment} from "../../environments/environment";
import {tap} from "rxjs/operators";


@Injectable()
export class StripeService{
    constructor(private _httpService: HttpService) {
    }

    public customerPortalSession2 = (id: string) => {
        const data = {
            customer: id,
            return_url: 'https://example.com/account',
        };

        return this._httpService.post(`https://api.stripe.com/v1/billing_portal/sessions`, data);
    }

    public customerPortalSession = (email: string) => {

        const data = {
            email: email,
            redirectUrl: `${document.location.origin}/profile`
        };
        return this._httpService.post(`${environment.apiUrl}/wallets/createCustomerPortalSession`, data).pipe(tap(result => {
            console.log('customerPortalSession pipe', result);
            window.location.href = result;
        }));
    }
}
