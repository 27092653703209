import { NgModule } from "@angular/core";
import { DecimalDirective } from "./decimal.directive";
import { DisableFormControlDirective } from "./disable-form-control.directive";
import { GoBackDirective } from "./go-back.directive";
import { IntDirective } from "./int.directive";
import { SecureClickDirective } from "./secure-click.directive";
import {AutofocusDirective} from "./autofocus.directive";

@NgModule({
    declarations: [
        GoBackDirective,
        IntDirective,
        SecureClickDirective,
        DecimalDirective,
        DisableFormControlDirective,
        AutofocusDirective
    ],
    exports: [
        GoBackDirective,
        IntDirective,
        SecureClickDirective,
        DecimalDirective,
        DisableFormControlDirective,
        AutofocusDirective
    ]
})
export class DirectivesModule {
}
