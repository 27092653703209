import { Injectable } from "@angular/core";
import { HttpService } from "app/core/services/http.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Country } from "app/models/countries.model";

@Injectable()
export class CountriesService {
    constructor(private _httpService: HttpService) {
    }

    public searchCountries = (freeSearch: string): Observable<Country[]> => {
        let filter = {
            freeSearch: freeSearch
        }
        return this._httpService.get(`${environment.apiUrl}/countries`, filter);
    }

    public getById = (id: string): Observable<Country[]> => {
        return this._httpService.get(`${environment.apiUrl}/countries/${encodeURIComponent(id)}`);
    }
}
