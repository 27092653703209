import { Injectable } from "@angular/core";
import { HttpService } from "app/core/services/http.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SearchResult } from "app/core/components/data-table/types";
import buildQuery from 'odata-query'
import { Instance, InstanceListItem, InstancesSearchCriteria, InstanceToSave, InstanceView } from "app/models/instance.model";
import { map } from "rxjs/operators";
import { SelectListitem } from "app/core/components/forms/types";

@Injectable()
export class InstancesService {
    constructor(private _httpService: HttpService) {
    }

    public searchInstances = (searchCriteria: InstancesSearchCriteria): Observable<SearchResult<InstanceListItem>> => {
        let filters = searchCriteria.toOdata();
        if (searchCriteria.freeText) {
            filters.filter = {
                or:
                    [
                        { PerformanceName: { contains: searchCriteria.freeText } },
                        { Id: { contains: searchCriteria.freeText } }
                    ]

            }
        }
        let queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/instances${queryParams}`);
    }

    public getInstance = (id: string): Observable<InstanceView> => {
        return this._httpService.get(`${environment.apiUrl}/instances/${encodeURIComponent(id)}`);
    }

    public getForNewInstance = (performanceId: string): Observable<Instance> => {
        return this._httpService.get(`${environment.apiUrl}/instances/new/${encodeURIComponent(performanceId)}`).pipe(map(m => {
            let ret = m;
            for (let i = 0; i < m.fields.length; i++) {
                const field = m.fields[i];
                if (field.items && field.items.any()) {
                    field.items = field.items.map(m => new SelectListitem(m, m));
                }
            }
            return ret;
        }));
    }

    public addInstance = (performanceId: string, instance: InstanceToSave): Observable<string> => {
        return this._httpService.post(`${environment.apiUrl}/instances/${encodeURIComponent(performanceId)}`, instance);
    }

    public upload = (file: File): Observable<any> => {
        const fileNameParts = file.name.split('.');
        let extension = `${fileNameParts[fileNameParts.length - 1].toLowerCase()}`;
        let tokenUrl = `${environment.apiUrl}/instances/temp-file/${extension}`
        return this._httpService.uploadToBlobStorage(tokenUrl, file);
    }
}
