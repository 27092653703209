import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'app/core/services/profile.service';
import { Profile, RoleTypes } from 'app/core/models/profile.model';
import { environment } from 'environments/environment';
import { CultureService } from 'app/core/services/culture.service';
import { AuthService } from 'app/core/services/auth.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { TourService } from 'app/services/tour.service';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit, OnDestroy {
    public profile: Profile;
    public initials: string;
    public fullName: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild(MatMenuTrigger) private _trigger: MatMenuTrigger;
    constructor(private _tourService: TourService, private _changeDetectorRef: ChangeDetectorRef, private _authService: AuthService, private _router: Router, private _profileService: ProfileService, private _cultureService: CultureService) {
    }

    ngOnInit(): void {
        // Subscribe to user changes
        this._profileService.user$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((profile: Profile) => {
                this.profile = profile;
                this.initials = this.getInitials(profile);
                this.fullName = this.getFullName(profile);

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this._tourService.openRightMenu.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._trigger.openMenu();
            })
        this._tourService.closeRightMenu.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._trigger.closeMenu();
            })
    }

    private getFullName = (profile: Profile): string => {
        let ret = null;
        if (profile) {
            ret = ((profile.firstName || "") + " " + (profile.lastName || "")).trim();

            if (!ret) {
                ret = (profile.businessName || "").trim();
            }
            if (!ret) {
                ret = profile.email;
            }
        }
        return ret;
    }

    private getInitials = (profile: Profile): string => {
        let ret = null;
        if (profile) {
            ret = this.calculateInitials(((profile.firstName || "") + " " + (profile.lastName || "")).trim());

            if (!ret) {
                ret = this.calculateInitials(profile.businessName);
            }
            if (!ret) {
                ret = this.calculateInitials(profile.email);
            }
        }
        return ret;
    }

    private calculateInitials = (value: string): string => {
        value = value || "";
        const splittedName = value.split(' ').filter(f => f != '');
        switch (splittedName.length) {
            case 0:
                return "";
            case 1:
                return splittedName[0].substr(0, 2);
            default:
                return splittedName.take(2).map(m => m.substr(0, 1)).join('');
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public changeCulture = (cultureCode: string): void => {
        if (this._cultureService.getCurrentCulture() != cultureCode) {
            this._cultureService.setCurrentCulture(cultureCode);
        }
    }

    public get cultureCodes(): string[] {
        return environment.UICulture;
    }

    public get cultureFlag(): string {
        let culture = this._cultureService.getCurrentCulture();
        return `/assets/images/flags/${culture}.png`;
    }

    public signOut = (): void => {
        this._authService.signOut();
    }

    public get canEditProfile(): boolean {
        return this.profile != null && (this.profile.type === RoleTypes.OrganizationAdmin || this.profile.type === RoleTypes.SingleUser || this.profile.type === RoleTypes.OrganizationUser)
    }
}

