import { Component, OnInit } from '@angular/core';
import {CultureService} from '../../../services/culture.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-privacy-v1',
  templateUrl: './privacy-v1.component.html',
  styleUrls: ['./privacy-v1.component.scss']
})
export class PrivacyV1Component implements OnInit {

    pdfSrc: any;

    constructor(private _cultureService: CultureService, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        console.log('culture: ' + this._cultureService.getCurrentCulture());
        if (this._cultureService.getCurrentCulture() === 'it'){
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://healthcor.blob.core.windows.net/termsconditions/20220121_v1_ITA_Informativa Utente.pdf'); // url it
        }else {
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://healthcor.blob.core.windows.net/termsconditions/20220121_v1_ENG_Informativa Utente.pdf'); // url en
        }
    }

}
