import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'errors-dialog',
    templateUrl: './errors-dialog.component.html',
})
export class ErrorsDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public errors:string[]) {

    }
}
