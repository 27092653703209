import {List} from "postcss/lib/list";
import {KeyValue} from "@angular/common";

export class BaseProfile {
    public email: string;
    public firstName: string;
    public lastName: string;
    public businessName: string;
    public streetName: string;
    public houseNumber: string;
    public zipCode: string;
    public city: string;
    public province: string;
    public countryId: string;
    public fiscalCode: string;
    public vatCode: string;
    public recipientCode: string;
    public hasFiscalData: boolean = false;
    public billingType: BillingTypes;
    public termsConditions: KeyValue<string, boolean>[]; // Map<string, boolean> = new Map<string, boolean>();
}

export class Profile extends BaseProfile {
    public type: RoleTypes;
    public hasFiscalData: boolean = false;
}


export enum RoleTypes {
    SystemUser = "SystemUser",
    OrganizationAdmin = "OrganizationAdmin",
    SingleUser = "SingleUser",
    OrganizationUser = "OrganizationUser"
}

export enum BillingTypes {
    SinglePerfomance = "SinglePerfomance",
    Monthly = "Monthly",
}

