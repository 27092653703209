import { SearchCriteria } from "app/core/components/data-table/types";
import { CountryTypes } from "./countries.model";
import { InstanceStatuses } from "./instance.model";
import { UserTypes } from "./user.model";

export class ReportListItem {
    public id: string;
    public description: string;
    public date: string;
    public amount: number;
    public userFullName: string;
    public firstLastName: string;
    public email: string;
    public userId: string;
    public performanceId: string;
    public performanceName: string;
    public organizationId: string;
    public organizationName: string;
    public status: InstanceStatuses;
}
export class Report extends ReportListItem {
    public userCountryType: CountryTypes;
    public userType: UserTypes;
    public sanitaryPerformance: boolean;
}



export class ReportsSearchCriteria extends SearchCriteria {
    public id: string = null;
    public freeText: string = null;
    public from: string = null;
    public to: string = null;
    public sanitary?: boolean = null;
    public userCountryType?: CountryTypes = null;
    public userType?: UserTypes = null;
    public status?: InstanceStatuses = null;
}
