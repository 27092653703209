import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, empty, from, combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { SpinnerService } from './spinner.service';
import { FilesUtils } from '../utils/files.utils';
import { AuthService } from './auth.service';
import { ErrorsDialogComponent } from '../components/dialogs/errors-dialog.component';
import { BlobServiceClient, AnonymousCredential, newPipeline } from '@azure/storage-blob';
import { TempFileInstance } from '../models/core.model';
import { TranslateService } from '@ngx-translate/core';
import { CultureService } from './culture.service';

@Injectable()
export class HttpService {
    constructor(private _dialogService: DialogService, private http: HttpClient, private _router: Router, private _spinnerService: SpinnerService, private _authService: AuthService, private _translateService: TranslateService, private _cultureService: CultureService) {
    }

    public get = <T = any>(url: string, data: any = {}, customHeaders: any = {}, responseType = "json"): Observable<T> => {
        let values = this.manageGetOptions(data, customHeaders);
        if (responseType) {
            values["responseType"] = responseType
        }
        return this.http.get<T>(url, values)
            .pipe(catchError((error, caught) => this.manageErrors(error, caught)));
    }

    public getOData = <T = any>(url: string, data: any = {}, customHeaders: any = {}): Observable<T> => {
        let oDataObject = new ODataFilters();
        for (let prop of data) {
            if (data[prop] != null) {

            }
        }
        let values = this.manageGetOptions(data, customHeaders);
        return this.http.get<T>(url, values)
            .pipe(catchError((error, caught) => this.manageErrors(error, caught)));
    }

    public post = <T = any>(url: string, data: any, customHeaders: any = {}, observe: string = 'body'): Observable<T> => {
        let options = {
            headers: this.getHeaders(false, customHeaders)
        }
        options["observe"] = observe;
        return this.http.post<T>(url, data, options)
            .pipe(catchError((error, caught) => this.manageErrors(error, caught)));
    }

    public put = <T = any>(url: string, data: any, customHeaders: any = {}, observe: string = 'body'): Observable<T> => {
        let options = {
            headers: this.getHeaders(false, customHeaders)
        }
        options["observe"] = observe;
        return this.http.put<T>(url, data, options)
            .pipe(catchError((error, caught) => this.manageErrors(error, caught)));
    }

    public delete = <T = any>(url: string, customHeaders: any = {}): Observable<T> => {
        return this.http.delete<T>(url, { headers: this.getHeaders(false, customHeaders) })
            .pipe(catchError((error, caught) => this.manageErrors(error, caught)));
    }

    public getFile = (url: string, data: any = {}, customHeaders: any = {}, get: boolean = true): Observable<any> => {
        if (get) {
            let values = this.manageGetOptions(data, customHeaders);
            values['responseType'] = "blob";
            values['observe'] = "response";
            return this.http.get<any>(url, values).pipe(tap(result => this.manageFileResponse(result)));
        }
        else {
            return this.http.post(url, data, {
                headers: this.getHeaders(false, customHeaders),
                responseType: 'blob',
                observe: "response"
            }).pipe(tap(result => this.manageFileResponse(result)));
        }
    }

    private manageFileResponse = (response: HttpResponse<any>): void => {
        const contentType = response.headers.get("content-type");
        const contentDisposition = response.headers.get("content-disposition");
        let filename = new Date().getTime().toString();
        if (contentDisposition && contentDisposition.indexOf('inline') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        FilesUtils.openFromByteArray(response.body, contentType, filename);
    }

    public uploadToBlobStorage = (tokenUrl: string, file: File): Observable<string> => {
        const fileNameParts = file.name.split('.');
        let extension = '';
        extension = `.${fileNameParts[fileNameParts.length - 1].toLowerCase()}`;
        //Mi prendo la url teporanea
        return this.get<TempFileInstance>(tokenUrl).pipe(mergeMap(result => {
            const pipeline = newPipeline(new AnonymousCredential());
            const blobServiceClient = new BlobServiceClient(`${result.uri}?${result.sasToken}`, pipeline);
            const containerClient = blobServiceClient.getContainerClient('');
            const client = containerClient.getBlockBlobClient(result.name);
            return combineLatest([from(client.uploadData(file, {
                blockSize: 4 * 1024 * 1024, // 4MB block size
                concurrency: 20, // 20 concurrency
                blobHTTPHeaders: { blobContentType: file.type }
            })), of(result)]);
        })).pipe(map(results => {
            return `${results[1].uri}/${results[1].name}?${results[1].sasToken}`;
        }));
    }

    private manageGetOptions = (data: any = {}, customHeaders: any = {}): any => {
        let parametersToSend: any = {};
        if (data != null) {
            for (let prop in data) {
                if (data[prop] != null) {
                    parametersToSend[prop] = data[prop];
                }
            }
        }
        return { headers: this.getHeaders(false, customHeaders), params: parametersToSend };
    }

    protected manageErrors = (err: any, caught: Observable<any>): Observable<any> => {
        this._spinnerService.hide();
        console.log(err);
        switch (err.status) {
            case 400:
                let errors: string[] = [];
                let errorResponse = err && err.error && err.error.errors ? err.error.errors : {};
                if (errorResponse) {
                    for (const prop in errorResponse) {
                        errors.push(...errorResponse[prop])
                    }
                }
                if (!errors.any()) {
                    errors.push(this._translateService.instant('common.genericError'));
                }
                this._dialogService.show(ErrorsDialogComponent, {
                    panelClass: "modal-md",
                    data: errors,
                    callback: () => { }
                });
                return throwError(err)
            case 401:
                this._authService.signOut();
                return empty();
            case 403:
                this._router.navigate(['error', 403]);
                return empty();
            case 500:

                this._dialogService.show(ErrorsDialogComponent, {
                    panelClass: "modal-md",
                    data: [this._translateService.instant('common.genericError')],
                    callback: () => { }
                });
                return throwError(err)
            default:
                this._router.navigate(['error', err.status]);
                return empty();
        }
    }

    private getHeaders = (forUpload: boolean = false, customHeaders: any = {}): HttpHeaders => {
        let ret = new HttpHeaders();
        ret = this.addHeader("accept-language", this._cultureService.getCurrentCulture(), ret);
        for (let prop in customHeaders) {
            ret = ret.set(prop, customHeaders[prop]);
        }
        if (!forUpload) {
            ret = this.addHeader("Content-Type", "application/json; charset=utf-8", ret);
            ret = this.addHeader("Accept", "application/json", ret);
        }
        const accessToken = this._authService.getAccessToken();
        if (accessToken) {
            ret = this.addHeader("Authorization", "Bearer " + accessToken, ret);
        }
        ret = this.manageHeaders(forUpload, ret);
        return ret;
    }

    private addHeader = (key: string, value: string, headers: HttpHeaders): HttpHeaders => {
        if (headers[key] == undefined) {
            headers = headers.set(key, value);
        }
        return headers;
    }

    protected manageHeaders = (forUpload: boolean = false, httpHeaders: HttpHeaders): HttpHeaders => {
        return httpHeaders;
    }
}

class ODataFilters {
    public $filter: string = null;
    public $orderby: string = null;
    public $top: number = 10;
    public $skip: number = 1;
    public $count: boolean = true;
}