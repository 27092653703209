import { Component, ViewEncapsulation } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { delay } from 'rxjs/operators';
import { SpinnerService } from 'app/core/services/spinner.service';
import { FuseAnimations } from '@fuse/animations';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: FuseAnimations

})
export class SpinnerComponent {
    public visible: boolean = false;

    constructor(private _spinnerService: SpinnerService) {
        this._spinnerService.toggle.pipe(delay(0)).subscribe(result => {
            this.visible = result;
        })
    }
}
