import { Component } from '@angular/core';
import {MessageService} from "primeng-lts/api";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Gtag} from "angular-gtag";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(public router: Router, gtag: Gtag, private activatedRoute: ActivatedRoute) {
        if (window.location.host.includes('healthcor.cardiocalm')){
            console.log('healthcor.cardiocalm');
            this.router.events.subscribe(event => {
                    if (event instanceof NavigationEnd){
                        gtag.pageview(
                            {
                                'page_path': event.urlAfterRedirects
                            }
                        );
                        gtag.event('on_view_' + event.urlAfterRedirects);
                    }
                }
            );
        }
    }
}
