import { Route } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { ResetPasswordGuard } from './core/guard/reset-password.guard';
import { LayoutComponent } from './layout/layout.component';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';
import {TermsConditionsGuard} from './core/guard/terms-conditions.guard';

export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'reset', pathMatch: 'full', canActivate: [ResetPasswordGuard], component: EmptyLayoutComponent },
    { path: 'home', component: EmptyLayoutComponent, loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    { path: 'error', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/errors/errors.module').then(m => m.ErrorsModule) },
    { path: 'admin', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
    { path: 'profile', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'organization', component: LayoutComponent, canActivateChild: [AuthGuard, TermsConditionsGuard], loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule) },
    { path: 'user', component: LayoutComponent, canActivateChild: [AuthGuard, TermsConditionsGuard], loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
    { path: 'dashboard', component: LayoutComponent, canActivateChild: [AuthGuard, TermsConditionsGuard], loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'faq', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/common/faq/faq.module').then(m => m.FaqModule) },
    { path: 'info', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/common/info/info.module').then(m => m.InfoModule) },
    { path: 'dashboard-start-tour', component: LayoutComponent, canActivateChild: [AuthGuard], loadChildren: () => import('./pages/common/tour/start-tour.module').then(m => m.StartTourModule) }
];
