<!-- Item wrapper -->
<div class="fuse-vertical-navigation-item-wrapper" [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper" [joyrideStep]="item.id" title="{{item.tourTitle | translate}}"
    text="{{item.tourMessage | translate}}" [prevTemplate]="prevButton" [nextTemplate]="nextButton"
    [doneTemplate]="doneButton">
    <!-- Item with an internal link -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && item.externalLink && !item.function && !item.disabled"
        [href]="item.link" target="_blank">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a function -->
    <div class="fuse-vertical-navigation-item" *ngIf="!item.link && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an internal link and function -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && !item.externalLink && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}" [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link and function -->
    <a class="fuse-vertical-navigation-item" *ngIf="item.link && item.externalLink && item.function && !item.disabled"
        [href]="item.link" (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a no link and no function -->
    <div class="fuse-vertical-navigation-item" *ngIf="!item.link && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item is disabled -->
    <div class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled" *ngIf="item.disabled">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
</div>

<!-- Item template -->
<ng-template #itemTemplate>
    <!-- Icon -->
    <mat-icon class="fuse-vertical-navigation-item-icon" [ngClass]="item.classes?.icon" *ngIf="item.icon"
        [svgIcon]="item.icon"></mat-icon>

    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
        <div class="fuse-vertical-navigation-item-title">
            <span [ngClass]="item.classes?.title">
                {{item.title | translate}}
            </span>
        </div>
        <div class="fuse-vertical-navigation-item-subtitle" *ngIf="item.subtitle">
            <span [ngClass]="item.classes?.subtitle">
                {{item.subtitle | translate}}
            </span>
        </div>
    </div>

    <!-- Badge -->
    <div class="fuse-vertical-navigation-item-badge" *ngIf="item.badge">
        <div class="fuse-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
            {{item.badge.title | translate}}
        </div>
    </div>
</ng-template>

<ng-template #prevButton>
    <button mat-flat-button color="accent" class="mr-4">{{'tour.back' |
        translate}}</button>
</ng-template>
<ng-template #nextButton>
    <button mat-flat-button color="primary"> {{'tour.next' | translate}}</button>
</ng-template>
<ng-template #doneButton>
    <button mat-flat-button color="primary"> {{'tour.complete' | translate}}</button>
</ng-template>