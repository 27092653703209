import { SearchCriteria } from "app/core/components/data-table/types";

export class WalletListItem {
    public id: string;
    public name: string;
    public credit: number;
    public performances: string[] = [];
}
export class WalletAutocomplete {
    public id: string;
    public name: string;
}
export class Wallet {
    public id: string;
    public name: string;
    public credit: number;
    public performances: WalletPerformance[] = []
    public showCredit: boolean = false;
    public showPerformancePrice: boolean = false;
}
export class WalletPerformance {
    public performanceId: string;
    public name: string;
    public enabled: boolean;
}
export class WalletTransaction {
    public id: string;
    public date: string;
    public reason: WalletTransactionTypes;
    public amount: number = 0;
}
export enum WalletTransactionTypes {
    Purchase = "Purchase",
    Transfer = "Transfer",
    Refund = "Refund",
    Refill = "Refill"
}
export class WalletToSave {
    public name: string;
    public performances: WalletPerformanceToSave[] = []
    public showCredit: boolean;
    public showPerformancePrice: boolean;
}
export class WalletPerformanceToSave {
    public performanceId: string;
    public enabled: boolean = true;
}



export class WalletsSearchCriteria extends SearchCriteria {
    public freeText: string = null;
}

export class WalletTransactionSearchCriteria extends SearchCriteria {
}

export class Transfer {
    public amount: number;
    public targetWalletId: string;
}
export class Buy {
    public redirectUri: string;
    public koRedirectUri: string;
    constructor(public amount: number, id: string) {
        const baseRedirectUri = `${document.location.origin}/organization/wallets/from-buy/${id}/?result=`
        this.redirectUri = `${baseRedirectUri}true`;
        this.koRedirectUri = `${baseRedirectUri}false`;
    }
}