import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { Layout } from 'app/layout/layout.types';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Theme = 'default' | string;

export interface IAppConfig {
    layout: Layout;
    scheme: Scheme;
    theme: Theme;
    formAppearance: MatFormFieldAppearance;
    formFloatLabel: FloatLabelType;
    cultureCodes: string[];
    resourcePaths: string[];
    defaultCultureCode: string;
}

export const appConfig: IAppConfig = {
    layout: 'classic',
    scheme: 'light',
    theme: 'default',
    formAppearance: 'outline',
    formFloatLabel: 'auto',
    resourcePaths: ["./assets/i18n/{code}.json"],
    cultureCodes: ["en", "it"],
    defaultCultureCode: "it"
};
