import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig, IAppConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InMemoryTranslateHttpLoader } from './core/services/in-memory-translate-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FUSE_APP_CONFIG } from '@fuse/services/config/config.constants';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthGuard } from './core/guard/auth.guard';
import { ResetPasswordGuard } from './core/guard/reset-password.guard';
import { JoyrideModule } from 'ngx-joyride';
import {TermsConditionsGuard} from "./core/guard/terms-conditions.guard";
import {ToastModule} from "primeng-lts/toast";
import {MessageService} from "primeng-lts/api";
import {GtagModule} from "angular-gtag";

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
};
export function HttpLoaderFactory(http: HttpClient, appConfig: IAppConfig) {
    return new InMemoryTranslateHttpLoader(http, appConfig);
}

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent
    ],
    providers: [
        AuthGuard,
        ResetPasswordGuard,
        TermsConditionsGuard,
        MessageService
    ],
    imports: [
        MatProgressSpinnerModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse & Fuse Mock API
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Layout
        LayoutModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, FUSE_APP_CONFIG]
            }
        }),

        OAuthModule.forRoot(),

        // Core
        CoreModule.forRoot(),

        // 3rd party modules
        MarkdownModule.forRoot({}),
        JoyrideModule.forRoot(),
        ToastModule,
        GtagModule.forRoot({ trackingId: 'G-GG08MDZHVL', trackPageviews: true })


    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

}
