import { Injectable, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment'
import { DateAdapter } from '@angular/material/core';
import { FUSE_APP_CONFIG } from '@fuse/services/config/config.constants';
import { IAppConfig } from '../config/app.config';
import { Subject } from 'rxjs';
import { SelectListitem } from '../components/forms/types';
import { environment } from 'environments/environment';
import { ServiceCulture } from '../models/core.model';




@Injectable({
    providedIn: 'root'
})
export class CultureService {
    public cultureChanged: Subject<string> = new Subject<string>();
    private _serviceCultures: ServiceCulture[] = [];
    constructor(private _translateService: TranslateService, private _adapter: DateAdapter<any>, @Inject(FUSE_APP_CONFIG) private _appConfig: IAppConfig) {
    }

    public initialize = (): void => {
        //leggo la culture dallo storage
        let cultureCode = window.localStorage.getItem("culture");
        if (!cultureCode) {
            cultureCode = this.getBrowerCulture();
        }
        this._translateService.addLangs(this._appConfig.cultureCodes);
        if (this._appConfig.cultureCodes.indexOf(cultureCode) < 0) {
            cultureCode = this._appConfig.defaultCultureCode;
        }
        this._translateService.setDefaultLang(cultureCode);
        this.setCurrentCulture(cultureCode);
    }

    public getCurrentCulture = (): string => {
        return this._translateService.currentLang;
    }

    public useBrowserCulture = (): void => {
        const cultureCode = this.getBrowerCulture();
        if (cultureCode) {
            this.setCurrentCulture(cultureCode, false);
        }
    }

    private getBrowerCulture = (): string => {
        let cultureCode: string = null;
        if (window.navigator && window.navigator.language) {
            cultureCode = window.navigator.language;
            if (this._appConfig.cultureCodes.indexOf(cultureCode) >= 0) {
                return cultureCode;
            }
            cultureCode = cultureCode.substr(0, 2);
            if (this._appConfig.cultureCodes.indexOf(cultureCode) >= 0) {
                return cultureCode;
            }
        }
        return null;
    }

    public setCurrentCulture = (culture: string, storeCulture: boolean = true): void => {

        // Label
        this._translateService.use(culture);

        //Datepicker
        this._adapter.setLocale(culture);

        //Moment
        moment.locale(culture)
        if (storeCulture) {
            window.localStorage.setItem("culture", culture);
        }

        this.cultureChanged.next(culture);
    }

    public getServiceCultures = (): ServiceCulture[] => {
        if (!this._serviceCultures.any()) {
            this._serviceCultures = environment.serviceCultures.map(m => <ServiceCulture>{
                code: m,
                name: this._translateService.instant(`serviceCultures.${m}`),
                flag: `/assets/images/flags/${m}.png`
            });
        }
        return this._serviceCultures;
    }
}