import { SearchCriteria } from "app/core/components/data-table/types";

export class Subscription {
    public id: string;
    public name: string;
    public performanceId: string;
    public performanceName: string;
    public performanceImage: string;
    public creationDate: string;
    public nextExpirationDate: string;
    public status: SubscriptionStatus;
    public period: SubscriptionPeriods;
}
export class SubscriptionAvailable {
    public id: string;
    public price: number;
    public name: string;
    public description: string;
    public period: string;
    public performanceId: string;
    public performanceName: string;
    public performanceImage: string;
}

export enum SubscriptionStatus {
    Pending = "Pending",
    Active = "Active",
    Cancelled = "Cancelled",
    PaymentFailed = "PaymentFailed",
    Expired = "Expired"
}


export enum SubscriptionPeriods {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    HalfYearly = "HalfYearly",
    Yearly = "Yearly"
}



export class SubscriptionsSearchCriteria extends SearchCriteria {
    public freeText: string = null;
    public id: string = null;
    public status?: SubscriptionStatus = null;
    public periods?: SubscriptionPeriods = null;
}

export class SubscriptionToSave {
    constructor(public id: string) {
        const baseRedirectUri = `${document.location.origin}/organization/subscriptions/from-buy/${id}?result=`;
        this.redirectUri = `${baseRedirectUri}true`;
        this.koRedirectUri = `${baseRedirectUri}false`;
    }
    public redirectUri: string
    public koRedirectUri: string
}
