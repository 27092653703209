import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ResetPasswordGuard implements CanActivate {
    constructor(private router: Router, private _authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.queryParams.email) {
            this._authService.resetPassword();
        } else {
            this.router.navigate(['/dashboard']);
        }
        return false;
    }
}