import { Component } from '@angular/core';

import { DialogConfig } from '../../services/dialog.service';




@Component({
    template: ''
})
export class BaseDialogComponent {
    public configuration: DialogConfig<any>
    constructor() {
    }
}