import {Type} from "@angular/core";

export class TermsConditionsModel{
    id: string;
    label: string;
    component: Type<any>;
    required: boolean;
    isAccepted: boolean;
    isViewed: boolean;
    acceptOnRead: boolean;

    constructor(id: string, label: string, component: Type<any>, required: boolean, acceptOnRead: boolean) {
        this.id = id;
        this.label = label;
        this.component = component;
        this.required = required;
        this.acceptOnRead = acceptOnRead;
        this.isAccepted = true;
        this.isViewed = true;
    }
}

export class TermsConditionsRequest{
    key: string;
    accepted: boolean;

    constructor(key: string, accepted: boolean) {
        this.key = key;
        this.accepted = accepted;
    }
}
