import { Component, OnInit } from '@angular/core';
import {CultureService} from '../../../services/culture.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-terms-v1',
  templateUrl: './terms-v1.component.html',
  styleUrls: ['./terms-v1.component.scss']
})
export class TermsV1Component implements OnInit {

    pdfSrc: any;

    constructor(private _cultureService: CultureService, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        // console.log('culture: ' + this._cultureService.getCurrentCulture());
        if (this._cultureService.getCurrentCulture() === 'it'){
            this.pdfSrc =
                this.sanitizer.bypassSecurityTrustResourceUrl('https://healthcor.blob.core.windows.net/termsconditions/20211201_v11_ITA_Condizioni_Generali.pdf'); // url it
        }else {
            this.pdfSrc =
                this.sanitizer.bypassSecurityTrustResourceUrl('https://healthcor.blob.core.windows.net/termsconditions/20211201_v1_ENG_Condizioni_Generali.pdf'); // url en
        }
    }

    /*public getConsultationDocumentPDF(): Observable<Response> {
        return this.http.get(
            "https://healthcor.blob.core.windows.net/termsconditions/ENG_Condizioni_Generali.pdf",
            {
                headers: new Headers({
                    "Access-Control-Allow-Origin": "*",
                }),
                responseType:ResponseContentType.ArrayBuffer // YOU NEED THAT
            }
        );

    }*/

}
