import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HttpErrorResponse, HttpHeaderResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

@Injectable()
export class HttpRestInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                let ret = event;
                if (event instanceof HttpResponse) {
                    if (event.status == 201) {
                        ret = event.clone({
                            body: event.headers.get("location"),
                            headers: event.headers,
                            status: event.status,
                            statusText: event.statusText,
                            url: event.url
                        });
                    }
                }
                return ret;
            }), catchError((ce: HttpErrorResponse) => {
                return throwError(ce);
            }));

    }
}