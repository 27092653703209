import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OAuthService, AuthConfig, UserInfo } from 'angular-oauth2-oidc';
import { filter, map, share, tap } from 'rxjs/operators';
import { from, merge, Observable, of } from 'rxjs';


@Injectable()
export class AuthService {
    private _loginState = "";
    private _singleUserRegistrationState = "SINGLE_USER_REGISTRATION";
    private _OrganizationAdminRegistrationState = "ORGANIZATION_ADMIN_REGISTRATION";
    constructor(private _oauthService: OAuthService) {
        this._oauthService.configure(this.getAuthConfig());
        this._oauthService.setStorage(sessionStorage);
        this._oauthService.setupAutomaticSilentRefresh();

    }

    private getAuthConfig = (): AuthConfig => {
        let fullPath = document.head.baseURI;
        if (fullPath.endsWith("/")) {
            fullPath = fullPath.substr(0, fullPath.length - 1);
        }
        let ret = new AuthConfig();
        ret.issuer = environment.oauthUrl;
        ret.redirectUri = fullPath;
        ret.strictDiscoveryDocumentValidation = false;
        ret.silentRefreshRedirectUri = fullPath + '/assets/authorization/silent-refresh.html'
        ret.clientId = environment.clientId;
        ret.responseType = "code"
        ret.scope = 'openid profile offline_access ' + environment.scope;
        ret.postLogoutRedirectUri = fullPath;

        return ret;
    }


    public verifyLogin = (): Observable<any> => {
        var url = this.getDiscoveryDocumentUrl();
        return from(this._oauthService.loadDiscoveryDocument(url).then(() =>
            this._oauthService.tryLogin()
        ).then(result => {
            let claims = this._oauthService.getIdentityClaims();
            return this._oauthService.hasValidAccessToken();
        }, error => false));
    }

    public get isAuthenticated() {
        return this._oauthService.hasValidAccessToken();
    }

    public resetPassword = (): void => {
        from(this._oauthService.loadDiscoveryDocument(environment.oauthdiscoveryDocumentUrlReset)).pipe(tap(result => {
            this._oauthService.initCodeFlow(this._loginState);
        })).subscribe();
    }

    public startLogin = (): void => {
        from(this._oauthService.loadDiscoveryDocument(environment.oauthdiscoveryDocumentUrlSignIn)).pipe(tap(result => {
            this._oauthService.initCodeFlow(this._loginState);
        })).subscribe();
    }

    public startUserSignUp = (): void => {
        from(this._oauthService.loadDiscoveryDocument(environment.oauthdiscoveryDocumentUrlSignUp)).pipe(tap(result => {
            this._oauthService.initCodeFlow(this._singleUserRegistrationState, { prompt: 'login' });
        })).subscribe();
    }

    public startOrganizationSignUp = (): void => {
        from(this._oauthService.loadDiscoveryDocument(environment.oauthdiscoveryDocumentUrlSignUpOrg)).pipe(tap(result => {
            this._oauthService.initCodeFlow(this._OrganizationAdminRegistrationState, { prompt: 'login' });
        })).subscribe();
    }

    public getAccessToken = (): string => {
        return this._oauthService.getAccessToken();
    }

    public signOut = (): void => {
        return this._oauthService.logOut();
    }

    private getDiscoveryDocumentUrl = (): string => {

        if (window.location.search.indexOf(this._singleUserRegistrationState) >= 0) {
            return environment.oauthdiscoveryDocumentUrlSignUp;
        }
        if (window.location.search.indexOf(this._OrganizationAdminRegistrationState) >= 0) {
            return environment.oauthdiscoveryDocumentUrlSignUpOrg;
        }
        return environment.oauthdiscoveryDocumentUrlSignIn;
    }
}



// @Injectable()
// export class AuthService {
//     constructor() {
//     }

//     public initialize = (): Observable<boolean> => {
//         return of(true);
//     }

//     public get isAuthenticated() {
//         return true;
//     }
//     public getAccessToken = (): string => {
//         return "1"
//     }

//     public logOut = (): void => {

//     }

//     public startLogin = (): void => {

//     }
// }

