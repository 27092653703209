// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    oauthUrl: "https://odfheritagedev.b2clogin.com/b8a6805a-ff0c-4e51-8fbe-becafb18ad39/v2.0/",
    oauthdiscoveryDocumentUrlReset: "https://healthcorb2c.b2clogin.com/healthcorb2c.onmicrosoft.com/B2C_1_Reset/v2.0/.well-known/openid-configuration",
    oauthdiscoveryDocumentUrlSignIn: "https://healthcorb2c.b2clogin.com/healthcorb2c.onmicrosoft.com/B2C_1_SignIn/v2.0/.well-known/openid-configuration",
    oauthdiscoveryDocumentUrlSignUp: "https://healthcorb2c.b2clogin.com/healthcorb2c.onmicrosoft.com/B2C_1_Signup/v2.0/.well-known/openid-configuration",
    oauthdiscoveryDocumentUrlSignUpOrg: "https://healthcorb2c.b2clogin.com/healthcorb2c.onmicrosoft.com/B2C_1_SignupOrg/v2.0/.well-known/openid-configuration",
    apiUrl: 'https://healthcor.azurewebsites.net',
    clientId: '',
    scope: '',
    // apiUrl: 'https://localhost:44342/',
    serviceCultures: ['it', 'en', 'fr', 'es', 'de'],
    UICulture: ['it', 'en']
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
