import { NgModule } from "@angular/core";
import { DatePipe, DateTimePipe, TimePipe } from "../pipes/date.pipe";
import { EnumPipe } from "../pipes/enum.pipe";
import { NumberPipe } from "../pipes/number.pipe";
import { BooleanPipe } from "./boolean.pipe";

@NgModule({
    declarations: [
        DateTimePipe,
        DatePipe,
        EnumPipe,
        NumberPipe,
        TimePipe,
        BooleanPipe
    ],
    exports: [
        DateTimePipe,
        DatePipe,
        EnumPipe,
        NumberPipe,
        TimePipe,
        BooleanPipe
    ]
})
export class PipesModule {
}
