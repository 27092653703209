import { SearchCriteria } from "app/core/components/data-table/types";

export class PerformanceListItem {
    public id: string;
    public key: string;
    public price: number;
    public trialAvailable: number;
    public enabled: boolean;
    public sanitary: boolean;
    public image: string;
    public name: string;
}

export class PerformanceForUserListItem {
    public id: string;
    public price: number;
    public name: string;
    public description: string;
    public image: string;
    public buyMode: BuyModes;
    public trialAvailable: number;
}

export enum BuyModes {
    NotAvailable = "NotAvailable",
    Buy = "Buy",
    Wallet = "Wallet",
    Subscription = "Subscription",
    Trial = "Trial",
    TaxRateNotAvailable = "TaxRateNotAvailable"
}

export class PerfomancesSearchCriteria extends SearchCriteria {
    public freeText: string = null;
}

export class PerfomancesUserPriceSearchCriteria extends SearchCriteria {
    public freeText: string = null;
}


export class Performance {
    public id: string;
    public key: string;
    public trialAvailable: number;
    public price: number;
    public enabled: boolean;
    public sanitary: boolean;
    public image: string;
    public names: any = {};
    public descriptions: any = {};
    public generalAvailability: boolean
}

export class PerformanceToSave {
    public price: number;
    public enabled: boolean;
    public sanitary: boolean;
    public names: any = {};
    public descriptions: any = {};
    public trialAvailable: number;
    public generalAvailability: boolean
}


export class PerformanceUserPrice {
    public performanceId: string;
    public userId: string;
    public userFullName: string;
    public type: PerformancePriceTypes;
    public value: number = 0;
}


export class PerformanceUserPriceSearchCriteria extends SearchCriteria {
    public freeText: string;
}

export class PerformanceUserPriceToSave {
    public type: PerformancePriceTypes;
    public value: number = 0;
}

export enum PerformancePriceTypes {
    Default = "Default",
    FixedPrice = "FixedPrice",
    Discount = "Discount"
}

export class PerformanceUserTypeEdit {
    public userId?: string;
    public performanceId: string;
}



export class PerformanceTaxRate {
    public id: string;
    public performanceId: string;
    public countryId: string;
    public type: PerformanceTaxRateTypes;
    public value: number = 0;
    public code: string;
    public description: string;
}

export class PerformanceTaxRateToSave {
    public type: PerformanceTaxRateTypes;
    public countryId: string;
    public value: number = 0;
    public code: string;
    public description: string;
}

export enum PerformanceTaxRateTypes {
    ItalianPrivate = "ItalianPrivate",
    ForeignPrivate = "ForeignPrivate",
    ItalianVat = "ItalianVat",
    UeVat = "UeVat",
    ExtraUeVat = "ExtraUeVat"
}
