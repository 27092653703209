import { Injectable } from "@angular/core";
import { Profile } from "app/core/models/profile.model";
import { ProfileService } from "app/core/services/profile.service";
import { defaultNavigation } from "app/shared/navigation";
import { JoyrideService } from "ngx-joyride";
import { JoyrideStepInfo } from "ngx-joyride/lib/models/joyride-step-info.class";
import { Subject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";

@Injectable()
export class TourService {
    private _rightMenuOpened: boolean = false;
    public openRightMenu: Subject<void> = new Subject<void>();
    public closeRightMenu: Subject<void> = new Subject<void>();
    constructor(private _joyrideService: JoyrideService, private _profileService: ProfileService) {

    }

    public startTour = (): void => {
        this._profileService.user$.pipe(map((profile: Profile) => {
            const items = defaultNavigation.find(f => f.id == profile.type).children.map(m => m.id);
            items.push("profile");
            items.push("culture");
            return items;
        }), delay(1000), tap((items) => {
            if (!window.localStorage.getItem("tour")) {
                this._joyrideService.startTour({
                    steps: items,
                    stepDefaultPosition: 'right'
                }).subscribe(this.manageMenu, error => { }, this.closeMenu);
                window.localStorage.setItem("tour", "started");
            }
        })).subscribe();
    }

    public resetTour = (): void => {
        if (window.localStorage.getItem("tour")) {
            window.localStorage.removeItem("tour");
        }
    }

    private manageMenu = (step: JoyrideStepInfo): void => {
        if ((step.name == "profile" || step.name == "culture")) {
            if (!this._rightMenuOpened) {
                window.scroll(0,0);
                this.openRightMenu.next();
                this._rightMenuOpened = true;
            }
        }
        else {
            this.closeMenu();
        }
    }

    private closeMenu = (): void => {
        if (this._rightMenuOpened) {
            this._rightMenuOpened = false;
            this.closeRightMenu.next();
        }
    }
}
