<h1 mat-dialog-title *ngIf="configuration.localize">{{data.title | translate}}</h1>
<h1 mat-dialog-title *ngIf="!configuration.localize">{{data.title}}</h1>
<mat-dialog-content>
    <p class="text-lg" *ngIf="configuration.localize">{{data.message | translate}}</p>
    <p class="text-lg" *ngIf="!configuration.localize">{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="close()"><span>{{ cancelLabel | translate}}</span></button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial><span>{{confirmLabel |
            translate}}</span></button>
</mat-dialog-actions>
