import { Injectable } from "@angular/core";
import { HttpService } from "app/core/services/http.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { PerfomancesSearchCriteria, PerformanceListItem, PerformanceUserPrice, PerformanceToSave, PerformanceUserPriceToSave, PerformanceForUserListItem, PerformanceTaxRate, PerformanceTaxRateToSave, PerformanceUserPriceSearchCriteria } from 'app/models/performance.model';
import { SearchResult } from "app/core/components/data-table/types";
import buildQuery from 'odata-query'
import { Performance } from 'app/models/performance.model';
import { map, mergeMap } from "rxjs/operators";

@Injectable()
export class PerformancesService {
    constructor(private _httpService: HttpService) {
    }

    public searchPerformances = (searchCriteria: PerfomancesSearchCriteria): Observable<SearchResult<PerformanceListItem>> => {
        let filters = searchCriteria.toOdata();
        if (searchCriteria.freeText) {
            filters.filter = {
                or:
                    [
                        { Name: { contains: searchCriteria.freeText } },
                        { Key: { contains: searchCriteria.freeText } }
                    ]

            }
        }
        let queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/performances${queryParams}`);
    }

    public getAllPerformances = (): Observable<PerformanceListItem[]> => {
        const searchCriteria = new PerfomancesSearchCriteria();
        searchCriteria.itemsPerPage = 100;
        return this.searchPerformances(searchCriteria).pipe(map(m => m.items));
    }

    public getAllForUsers = (): Observable<PerformanceForUserListItem[]> => {
        const searchCriteria = new PerfomancesSearchCriteria();
        searchCriteria.itemsPerPage = 100;
        let filters = searchCriteria.toOdata();
        if (searchCriteria.freeText) {
            filters.filter = {
                or:
                    [
                        { Name: { contains: searchCriteria.freeText } },
                        { Description: { contains: searchCriteria.freeText } }
                    ]

            }
        }
        let queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/performances/for-users${queryParams}`).pipe(map(m => m.items));
    }


    public getByIdForUsers = (id: string): Observable<PerformanceForUserListItem> => {
        const searchCriteria = new PerfomancesSearchCriteria();
        searchCriteria.itemsPerPage = 100;
        let filters = searchCriteria.toOdata();
        filters.filter = {
            and:
                [
                    { id: id }
                ]

        }
        let queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/performances/for-users${queryParams}`).pipe(map(m => m.items.any() ? m.items[0] : null));
    }
    public getPerformance = (id: string): Observable<Performance> => {
        return this._httpService.get(`${environment.apiUrl}/performances/${encodeURIComponent(id)}`);
    }
    public savePerformance = (id: string, performance: PerformanceToSave): Observable<void> => {
        return this._httpService.put(`${environment.apiUrl}/performances/${encodeURIComponent(id)}`, performance);
    }

    public getPerformanceUserPrices = (id: string, searchCriteria: PerformanceUserPriceSearchCriteria): Observable<SearchResult<PerformanceUserPrice>> => {
        let filters = searchCriteria.toOdata();
        if (searchCriteria.freeText) {
            filters.filter = { and: [{ UserFullName: { contains: searchCriteria.freeText } }] };
        }
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/performances/${encodeURIComponent(id)}/prices${queryParams}`);
    }
    public getPerformanceUserPrice = (performanceId: string, userId: string): Observable<PerformanceUserPrice> => {
        return this._httpService.get(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/prices/${encodeURIComponent(userId)}`);
    }
    public removePerformanceUserPrice = (performanceId: string, userId: string): Observable<void> => {
        return this._httpService.delete(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/prices/${encodeURIComponent(userId)}`);
    }
    public addPerformanceUserPrice = (performanceId: string, userId: string, data: PerformanceUserPriceToSave): Observable<void> => {
        return this._httpService.post(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/prices/${encodeURIComponent(userId)}`, data);
    }
    public editPerformanceUserPrice = (performanceId: string, userId: string, data: PerformanceUserPriceToSave): Observable<void> => {
        return this._httpService.put(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/prices/${encodeURIComponent(userId)}`, data);
    }



    public getPerformanceTaxRates = (performanceId: string): Observable<PerformanceTaxRate[]> => {
        return this._httpService.get(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/taxrates`).pipe(map(m => m.items));
    }
    public getPerformanceTaxRate = (performanceId: string, id: string): Observable<PerformanceTaxRate> => {
        return this._httpService.get(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/taxrates/${encodeURIComponent(id)}`);
    }
    public removePerformanceTaxRate = (performanceId: string, id: string): Observable<void> => {
        return this._httpService.delete(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/taxrates/${encodeURIComponent(id)}`);
    }
    public addPerformanceTaxRate = (performanceId: string, data: PerformanceTaxRateToSave): Observable<void> => {
        return this._httpService.post(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/taxrates`, data);
    }
    public editPerformanceTaxRate = (performanceId: string, id: string, data: PerformanceTaxRateToSave): Observable<void> => {
        return this._httpService.put(`${environment.apiUrl}/performances/${encodeURIComponent(performanceId)}/taxrates/${encodeURIComponent(id)}`, data);
    }


}
