import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from 'app/core/directives/directives.module';
import { PipesModule } from 'app/core/pipes/pipes.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { CloseScrollStrategy } from '@angular/cdk/overlay/scroll';
import { Overlay } from '@angular/cdk/overlay';
import {MAT_AUTOCOMPLETE_SCROLL_STRATEGY} from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import {DropdownModule} from 'primeng-lts/dropdown';
import {FileUploadModule} from 'primeng-lts/fileupload';
import {DragDropModule} from 'primeng-lts/dragdrop';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
    return () => overlay.scrollStrategies.close();
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatListModule,
        MatDialogModule,
        DirectivesModule,
        MatExpansionModule,
        PipesModule,
        MatCardModule,
        MatSnackBarModule,
        MatTabsModule,
        MatChipsModule,
        DropdownModule,
        FileUploadModule,
        DragDropModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatListModule,
        MatDialogModule,
        DirectivesModule,
        PipesModule,
        MatExpansionModule,
        MatCardModule,
        MatSnackBarModule,
        MatTabsModule,
        MatChipsModule,
        DropdownModule,
        FileUploadModule,
        DragDropModule
    ],
    providers: [
        { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
        { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
    ]
})
export class SharedModule {
}
