import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import '../extensions-methods/array.extensions';
import '../extensions-methods/form.extensions';
import { IAppConfig } from '../config/app.config';
@Injectable()
export class InMemoryTranslateHttpLoader extends TranslateLoader {
    private _data: TranslationResult[] = [];
    /**
     *
     */
    constructor(private _httpClient: HttpClient, private _appConfig: IAppConfig) {
        super();
    }


    public initialize = (): Observable<TranslationResult[]> => {
        let httpCalls: Observable<any>[] = []
        for (let i = 0; i < this._appConfig.resourcePaths.length; i++) {
            for (let x = 0; x < this._appConfig.cultureCodes.length; x++) {
                let url = this._appConfig.resourcePaths[i].replace("{code}", this._appConfig.cultureCodes[x]);
                httpCalls.push(this._httpClient.get(url).pipe(map(result =>
                    new TranslationResult(this._appConfig.cultureCodes[x], result)
                )));
            }
        }
        return forkJoin(httpCalls).pipe(tap(results => {
            this._data = results;
        }));
    }

    public getTranslation(lang: string): Observable<any> {
        let ret = this._data.find(f => f.cultureCode == lang);
        return of(ret ? ret.data : null);
    }
}

class TranslationResult {
    constructor(public cultureCode: string, public data: any) { }
}