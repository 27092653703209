import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SnackBarService {
    constructor(private _matSnackBar: MatSnackBar, private _translateService: TranslateService) {

    }

    public success = (message: string, params: Object = null): void => {
        this.showSnackBar(message, params, ["bg-green-600", "text-white"]);
    }
    public info = (message: string, params: Object = null): void => {
        this.showSnackBar(message, params);
    }
    public error = (message: string, params: Object = null): void => {
        this.showSnackBar(message, params, ["warn"]);
    }

    private showSnackBar = (message: string, params: Object, panelClasses: string[] = []): void => {
        let translatedMessage = this._translateService.instant(message, params);
        this._matSnackBar.open(translatedMessage, this._translateService.instant('common.close'), {
            verticalPosition: 'top',
            duration: 5000,
            panelClass: panelClasses
        });

    }
}
