import { Injectable } from '@angular/core';
import { ODataFilter, SearchResult } from 'app/core/components/data-table/types';
import { HttpService } from 'app/core/services/http.service';
import { OrganizationOrUser, OrganizationOrUserToSave, UserAutocomplete, UserListItem, UsersSearchCriteria, UserTypes } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import buildQuery from 'odata-query'
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {
    constructor(private _httpService: HttpService) {
    }

    public searchUsers = (searchCriteria: UsersSearchCriteria): Observable<SearchResult<UserListItem>> => {
        const filters = this.createODataFilter(searchCriteria);
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/users${queryParams}`);
    }

    public searchOrganizationUsers = (searchCriteria: UsersSearchCriteria): Observable<SearchResult<UserListItem>> => {
        searchCriteria.type = UserTypes.OrganizationUser;
        const filters = this.createODataFilter(searchCriteria);
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/users${queryParams}`);
    }


    public getUser = (id: string): Observable<OrganizationOrUser> => {
        return this._httpService.get(`${environment.apiUrl}/users/manage/${encodeURIComponent(id)}`);
    }

    public deleteUser = (id: string): Observable<void> => {
        return this._httpService.delete(`${environment.apiUrl}/users/${encodeURIComponent(id)}`);
    }
    public saveUser = (user: OrganizationOrUserToSave, id: string): Observable<OrganizationOrUserToSave> => {

        return this._httpService.put(`${environment.apiUrl}/users/manage/${encodeURIComponent(id)}`, user);
    }
    public addUser = (user: OrganizationOrUserToSave): Observable<string> => {

        return this._httpService.post(`${environment.apiUrl}/users/manage`, user).pipe(map((result: string) => {
            let parts = result.split('/');
            return parts[parts.length - 1];
        }));
    }

    public autocomplete = (searchString: string): Observable<UserAutocomplete[]> => {
        let searchCriteria = new UsersSearchCriteria();
        searchCriteria.pageIndex = 1;
        searchCriteria.itemsPerPage = 100;
        searchCriteria.freeText = searchString;
        const filters = this.createODataFilter(searchCriteria);

        filters.select = "id,fullName";

        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/users${queryParams}`).pipe(map(m => {
            return m.items;
        }));
    }

    private createODataFilter = (searchCriteria: UsersSearchCriteria): ODataFilter => {
        let filters = searchCriteria.toOdata();
        filters.filter = { and: [] };
        if (searchCriteria.type) {
            filters.filter.and.push({ Type: searchCriteria.type });
        }
        if (searchCriteria.freeText) {
            filters.filter.and.push({
                or:
                    [
                        { Email: { contains: searchCriteria.freeText } },
                        { FullName: { contains: searchCriteria.freeText } }
                    ]
            });
        }
        return filters;
    }
}
