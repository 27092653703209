<!-- Button -->
<div [matMenuTriggerFor]="userActions" class="cursor-pointer user-header-container">
    <div class="hidden sm:inline">{{fullName}}</div>
    <button mat-icon-button class="custom-header-user-btn bg-primary mr-2 text-on-primary">
        <span class="relative">
            {{initials || "-"}}
        </span>
    </button>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'profile.signedIn' | translate}}</span>
            <span class="mt-1.5 text-md font-medium">{{profile?.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2" *ngIf="canEditProfile"></mat-divider>
    <button mat-menu-item *ngIf="canEditProfile" routerLink="/profile" [joyrideStep]="'profile'" stepPosition="left"
        title="{{'tour.rightMenu.profile.title' | translate}}" text="{{'tour.rightMenu.profile.message' | translate}}">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{'profile.profile' | translate}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [matMenuTriggerFor]="profileLanguage" [joyrideStep]="'culture'" stepPosition="left"
        title="{{'tour.rightMenu.culture.title' | translate}}" text="{{'tour.rightMenu.culture.message' | translate}}">
        <img [src]="cultureFlag" class="flag" />
        <span>{{'profile.language' | translate}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{'profile.logout' | translate}}</span>
    </button>
</mat-menu>


<mat-menu class="user-cultures-menu" #profileLanguage="matMenu">
    <button mat-menu-item (click)="changeCulture(cultureCode)" *ngFor="let cultureCode of cultureCodes">
        <img [src]="'/assets/images/flags/' + cultureCode + '.png'" class="flag" />
        <span>{{('cultures.' + cultureCode) | translate }}</span>
    </button>
</mat-menu>
