import { TemplateRef } from "@angular/core";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ConfirmDialogComponent } from "../components/dialogs/confirm-dialog.component";

@Injectable()
export class DialogService {
    constructor(private _dialog: MatDialog) {

    }


    public showConfirm = (title: string, message: string, config: DialogConfig<any> = new DialogConfig<any>(),
                          confirmLabelButton?: string, cancelLabelButton?: string): void => {
        config.data = { title: title, message: message };
        config.localize = config.localize !== false;
        let dialogRef = this._dialog.open(ConfirmDialogComponent, config);

        dialogRef.componentInstance.configuration = config;
        dialogRef.componentInstance.confirmLabel = confirmLabelButton;
        dialogRef.componentInstance.cancelLabel = cancelLabelButton;

        let dialogRefSubscription = dialogRef.afterClosed().subscribe(result => {
            if (config.callback) {
                config.callback(result);
            }
            dialogRefSubscription.unsubscribe();
        });
    }
    public show = <T = any>(dialogComponent: ComponentType<any> | TemplateRef<any>, config: DialogConfig<T> = new DialogConfig<T>()): void => {
        config.localize = config.localize !== false;
        let dialogRef = this._dialog.open(dialogComponent, config)
        dialogRef.componentInstance.config = config;

        let dialogRefSubscription = dialogRef.afterClosed().subscribe((result: T) => {
            if (config.callback) {
                config.callback(result);
            }
            dialogRefSubscription.unsubscribe();
        });
    }
}

export class DialogConfig<T> extends MatDialogConfig<any>{
    constructor() {
        super()
        this.panelClass = "modal-md";
    }
    public localize?: boolean;
    public callback?: (data: T) => void;
}
