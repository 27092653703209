import { Injectable } from '@angular/core';
import { SearchCriteria, SearchResult } from 'app/core/components/data-table/types';
import { HttpService } from 'app/core/services/http.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import buildQuery from 'odata-query'
import { Subscription, SubscriptionAvailable, SubscriptionPeriods, SubscriptionsSearchCriteria, SubscriptionStatus, SubscriptionToSave } from 'app/models/subscriptions.model';
import { map, tap } from 'rxjs/operators';
import {InstanceStatuses} from "../models/instance.model";

@Injectable()
export class SubscriptionsService {
    constructor(private _httpService: HttpService) {
    }

    public searchSubscriptions = (searchCriteria: SubscriptionsSearchCriteria): Observable<SearchResult<Subscription>> => {
        const filters = searchCriteria.toOdata();
        filters.filter = { and: [] };
        if (searchCriteria.freeText) {
            filters.filter.and.push({
                or:
                    [
                        { PerformanceName: { contains: searchCriteria.freeText } },
                        { Name: { contains: searchCriteria.freeText } },
                        { Status: this.getEnumStatus(searchCriteria.freeText)},
                       // { Period: this.getEnumPeriods(searchCriteria.freeText)}
                    ]
            });
        }
        if (searchCriteria.id) {
            filters.filter.and.push({
                or:
                    [
                        { Id:  searchCriteria.id }
                    ]
            });
        }
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/subscriptions${queryParams}`);
    }

    public getEnumStatus = (value: string) => {
        switch (value.toLocaleLowerCase().trim()){
            case 'pending' :
                return SubscriptionStatus.Pending;
                break;
            case 'paymentfailed' :
                return SubscriptionStatus.PaymentFailed;
                break;
            case 'active' :
                return SubscriptionStatus.Active;
                break;
            case 'expired' :
                return SubscriptionStatus.Expired;
                break;
            case 'cancelled' :
                return SubscriptionStatus.Cancelled;
                break;
        }
    }

    public getEnumPeriods = (value: string) => {
        switch (value.toLocaleLowerCase().trim()){
            case 'halfyearly' :
                return SubscriptionPeriods.HalfYearly;
                break;
            case 'daily' :
                return SubscriptionPeriods.Daily;
                break;
            case 'monthly' :
                return SubscriptionPeriods.Monthly;
                break;
            case 'weekly' :
                return SubscriptionPeriods.Weekly;
                break;
            case 'yearly' :
                return SubscriptionPeriods.Yearly;
                break;
            case 'quarterly' :
                return SubscriptionPeriods.Quarterly;
                break;
        }
    }

    public getSubscription = (id: string): Observable<Subscription> => {
        const searchCriteria = new SubscriptionsSearchCriteria();
        searchCriteria.id = id;
        return this.searchSubscriptions(searchCriteria).pipe(map(result => {
            if (result.items.any()) {
                return result.items[0];
            }
            return null;
        }));
    }

    public getAvailables = (): Observable<SubscriptionAvailable[]> => {
        const searchCriteria = new SearchCriteria();
        searchCriteria.itemsPerPage = 100;
        const filters = searchCriteria.toOdata();
        const queryParams = buildQuery(filters);
        return this._httpService.get(`${environment.apiUrl}/subscriptions/available${queryParams}`).pipe(map((m: SearchResult<SubscriptionAvailable>) => {
            return m.items;
        }));
    }

    public delete = (id: string): Observable<void> => {
        return this._httpService.delete(`${environment.apiUrl}/subscriptions/${encodeURIComponent(id)}`);
    }

    public subscribe = (subscription: SubscriptionToSave): Observable<string> => {
        return this._httpService.post(`${environment.apiUrl}/subscriptions/subscribe`, subscription).pipe(tap(result => {
            window.location.href = result;
        }));
    }
}
