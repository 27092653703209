import { Injectable, Type } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { BaseDataTableCellTemplateComponent } from './data-table-cell-template.component';

export class DataTableColumn {
    public name: string;
    public label?: string;
    public sortable: boolean = true;
    public alignment: DataTableColumnAlignment = DataTableColumnAlignment.LEFT;
    public template?: Type<BaseDataTableCellTemplateComponent>;
    public metadata: any = {};
}


export enum DataTableColumnAlignment {
    LEFT = "text-left",
    CENTER = "text-center",
    RIGHT = "text-right"
}


export class DataTableAction {
    public funcToInvoke: (row: any) => void = (row) => { };
    public label: string;
    public icon: string;
    public alwaysVisible: boolean = true;
    public enableFunc: (row: any) => boolean = (row) => true;
}

export interface ITableManager<T> {
    data: BehaviorSubject<ListData<T>>;
}

export interface IDataTableManager<T> extends ITableManager<T> {
    searchCriteria: SearchCriteria;
    searchCallback: (pageIndex: number, pageSize: number, reloading: boolean, sortField?: string, sortAsc?: boolean) => void;
    gridCode: string;
    loading: Subject<boolean>;
    startSearch: () => void;
    startReload: () => void;
    destroy: () => void;
    updateCurrentItems: (items: T[]) => void;
    getCurrentItems: () => T[];
}

export class RemoteDataTableManager<TData = any, TSearchCriteria extends SearchCriteria = SearchCriteria> implements IDataTableManager<TData> {
    public data = new BehaviorSubject<ListData<TData>>(new ListData<TData>([], 0, 0));
    public searchCriteria: TSearchCriteria;
    public loading: Subject<boolean> = new Subject<boolean>();
    constructor(public gridCode: string = null, private _searchFunc: (searchCriteria: SearchCriteria, reloading: boolean) => Observable<SearchResult<TData>>, private setSearchCriteriaFunc: (searchCriteria: SearchCriteria) => void = (searchCriteria: SearchCriteria) => { }, initialSearchCriteria: TSearchCriteria = null) {
        this.searchCriteria = initialSearchCriteria || <TSearchCriteria>(new SearchCriteria());
    }
    public searchCallback = (pageIndex: number, pageSize: number, reloading: boolean, sortField?: string, sortAsc?: boolean): void => {
        this.searchCriteria = <TSearchCriteria>new SearchCriteria();
        this.searchCriteria.pageIndex = pageIndex;
        this.searchCriteria.itemsPerPage = pageSize;
        this.searchCriteria.field = sortField;
        this.searchCriteria.ascending = sortAsc || false;
        this.setSearchCriteriaFunc(this.searchCriteria);
        this.search(this.searchCriteria, reloading);
    }

    public startSearch = (): void => {
        this.searchCriteria.pageIndex = 1;
        this.setSearchCriteriaFunc(this.searchCriteria);
        this.search(this.searchCriteria, false);
    }
    public startReload = (): void => {
        this.search(this.searchCriteria, false);
    }

    private search = (searchCriteria: TSearchCriteria, reloading: boolean): void => {
        this.loading.next(true);
        this._searchFunc(searchCriteria, reloading).subscribe(result => {
            let data = new ListData(result.items, result.count, searchCriteria.pageIndex);
            this.data.next(data);
            this.loading.next(false);
        });
    }

    public updateCurrentItems = (data: TData[]): void => {
        let clonedData = <ListData<TData>>(JSON.parse(JSON.stringify(this.data.value)));
        clonedData.data = data;
        this.data.next(clonedData);
    }

    public getCurrentItems = (): TData[] => {
        return (<ListData<TData>>(JSON.parse(JSON.stringify(this.data.value)))).data;
    }


    public destroy = (): void => {
        this.data.complete();
    }
}

export class ListData<T> {
    constructor(public data: T[], public count: number, public pageIndex: number) {

    }
}


export class SearchResult<T>{
    public items: T[] = [];
    public count: number = 0;
}


export class SearchCriteria {
    public itemsPerPage: number = 10;
    public pageIndex: number = 1;
    public ascending: boolean = true;
    public field: string = null;

    public toOdata = (): ODataFilter => {
        return {
            orderBy: this.field ? (this.field + (this.ascending ? " asc" : " desc")) : null,
            skip: (this.pageIndex - 1) * this.itemsPerPage,
            top: this.itemsPerPage,
            filter: {},
            count: true,
            select: ""
        };
    }
}
export class ODataFilter {
    public orderBy: string;
    public top: number;
    public skip: number;
    public filter: any = {};
    public count: boolean = true;
    public select: string;

}
@Injectable()
export class CustomMatPaginator extends MatPaginatorIntl {
    constructor(private _translateService: TranslateService) {
        super();

        this.itemsPerPageLabel = this._translateService.instant("common.itemsPerPage");
    }
}
